import * as React from "react";
import "normalize.css";
import "../style/Fonts.css";
import "../style/layouts.css";
import UEp from "../components/paragragh";
import TitleCard from "../components/title";
import Footer from "../components/footer"
import PictureCard from "../components/horizontalcard";
import process1 from "../images/services1.jpg";
import process2 from "../images/services2.jpg";
import process3 from "../images/services3.jpg";
import Navbar from "../components/Navbar";
import Layout from "../components/layout";
import styled from "styled-components";
import * as constants from "../style/constants";
import { useMediaQuery } from "react-responsive";

const StyledMain = styled.main`
  @media screen and (max-width: 413px) {
    padding-top: 100px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 414px) and (max-width: 767px) {
    padding-top: 130px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 120px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    padding-left: 220px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 1500px) {
    padding-left: 220px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
`;
const IndexPage = () => {
  const isSmallPhone = useMediaQuery({ query: "(max-width: 413px)" });
  const isPhone = useMediaQuery({
    query: "(min-width: 414px) and (max-width: 767px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1499px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1500px)" });
  return (
    <Layout responsive={isSmallPhone || isPhone || isTablet ? "phone" : ""}>
      <StyledMain>
        {(isTablet || isLaptop || isBigScreen) && <Navbar />}
        <title>Our services</title>
        <TitleCard>
          <br />
          我们提供的
          <br />
          不只是服务
          <br />
          是多年服务经验
          <br />
          和认真辛勤的态度。
        </TitleCard>
        <div style={{ marginTop: "5rem" }}></div>
        <PictureCard
          src={process1}
          title={"本地化服务"}
          width={
            (isSmallPhone && "300px") ||
            (isPhone && "390px") ||
            (isTablet && "540px") ||
            (isLaptop && "400px") ||
            (isBigScreen && "740px")
          }
          height={
            (isSmallPhone && "200px") ||
            (isPhone && "260px") ||
            (isTablet && "360px") ||
            (isLaptop && "267px") ||
            (isBigScreen && "493px")
          }
        >
          我们确保您的游戏无论是在文化或者内容上都能成功。已经有一款成功的游戏，可是对任一方面的本地化不满意吗？
          与我们联系以获取适合您的游戏的报价。
        </PictureCard>
        <div style={{ marginTop: "5rem" }}></div>
        <PictureCard
          src={process2}
          title={"持续的支持"}
          width={
            (isSmallPhone && "300px") ||
            (isPhone && "390px") ||
            (isTablet && "540px") ||
            (isLaptop && "400px") ||
            (isBigScreen && "740px")
          }
          height={
            (isSmallPhone && "200px") ||
            (isPhone && "260px") ||
            (isTablet && "360px") ||
            (isLaptop && "267px") ||
            (isBigScreen && "493px")
          }
        >
          我们的专家团队将随时为您服务。 为您的游戏提供无缝的通信和支持服务。
        </PictureCard>
        <div style={{ marginTop: "5rem" }}></div>
        <PictureCard
          src={process3}
          title={"社交媒体服务"}
          width={
            (isSmallPhone && "300px") ||
            (isPhone && "390px") ||
            (isTablet && "540px") ||
            (isLaptop && "400px") ||
            (isBigScreen && "740px")
          }
          height={
            (isSmallPhone && "202px") ||
            (isPhone && "280px") ||
            (isTablet && "388px") ||
            (isLaptop && "287px") ||
            (isBigScreen && "500px")
          }
        >
          我们可以为您的游戏发展并维持活跃的社区。 您有什么特别的需求吗？
          给我们发送消息，我们将很高兴为您找到适合您的服务！
        </PictureCard>
        <div
          style={{ marginTop: "5rem", marginLeft: "10px", marginRight: "10px" }}
        >
          <UEp>
            还不确定我们是否可以处理您的项目吗？联系我们，我们很乐意提供历史合作案例供您参考。
          </UEp>
        </div>
      </StyledMain>
      <Footer />
    </Layout>
  );
};
export default IndexPage;
