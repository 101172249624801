import React from "react";
import styled from "styled-components";
import UEh2 from "./smallheading";
import UEp from "./smalpp";
const RspDiv = styled((props) => <div {...props} />)`
  display: flex;
  justifycontent: flex-start;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
  @media screen and (max-width: 374px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 90%;
  }
  @media screen and (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 90%;
  }
`;
const StyledDiv = styled((props) => <div {...props} />)`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: ${(props) => (props.side == "ltr" ? "row" : "row-reverse")};
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 374px) {
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justifycontent: flex-start;
    align-items: center;
  }
  @media screen and (min-width: 769px) and (max-width: 1499px) {
    display: flex;
    flex-direction: ${(props) => (props.side == "ltr" ? "row" : "row-reverse")};
    justify-content: space-around;
    align-items: center;
  }
`;
export default function picturecard({
  children,
  src,
  title,
  width,
  height,
  side,
}) {
  return (
    <StyledDiv side={side}>
      <img src={src} style={{ width: width, height: height }} />
      <RspDiv>
        <UEh2>{title}</UEh2>
        <UEp>{children}</UEp>
      </RspDiv>
    </StyledDiv>
  );
}
